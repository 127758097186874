/*
 * @Author: lijianling
 * @Date: 2022-08-09 10:20:39
 * @LastEditors: Cheng Zhao
 * @LastEditTime: 2022-12-13 18:56:01
 * @Description: get url
 */
import { DOMAIN_MARK } from '@/constant/url';
import { envSelect, Local, localeSelect } from '@const/index';

// only for GET requests, if using POST, need to use origin link (e.g. api-img-ori.seabank.ph)
export const getCdnDomain = localeSelect({
  ph: () => {
    const env = process.env.SHOPEE_ENVIRONMENT || 'live';

    return envSelect({
      live: 'api-img.seabank.ph',
      dev: `api-${env}.test.seabank.ph`,
      test: `api-${env}.test-stable.seabank.ph`,
      sit: `api-${env}.test-stable.seabank.ph`,
      uat: `cdn-api-img-${env}.uat.seabank.ph`,
      staging: 'cdn-api-img.staging.seabank.ph',
      default: 'api-img.seabank.ph',
    });
  },
  id: () => {
    const env = process.env.SHOPEE_ENVIRONMENT || 'live';

    return envSelect({
      live: 'cdn-api-img.seabank.co.id',
      dev: Local.ceph,
      test: Local.ceph,
      sit: Local.ceph,
      uat: `cdn-api-img-${env}.uat.seabank.co.id`,
      staging: 'cdn-api-img.staging.seabank.co.id',
      default: 'cdn-api-img.seabank.co.id',
    });
  },
});

export const getStaticDomain = (): string => {
  const env = process.env.SHOPEE_ENVIRONMENT || 'live';

  if (env.includes('live')) {
    return 'static.seabank.co.id';
  } else if (env.includes('uat1')) {
    return `static-uat1.uat.seabank.co.id`;
  } else if (
    env.includes('uat2') ||
    env.includes('sit') ||
    env.includes('dev') ||
    env.includes('test')
  ) {
    return '';
  } else if (env.includes('staging')) {
    return `static.staging.seabank.co.id`;
  }

  return 'static.seabank.co.id';
};

export const getImageUrl = (url?: string) => {
  if (!url) return '';
  const reg = new RegExp(
    `(http://${DOMAIN_MARK})|(https://${DOMAIN_MARK})`,
    'g',
  );
  return url.replace(reg, `//${getCdnDomain?.()}`);
};
