import { toDataURL } from 'qrcode';
import { ENV, COUNTRY } from '@const/index';

const langMap: Record<string, any> = {
  sg: 'en',
  ph: 'en',
};

export const defaultL = COUNTRY ? langMap[COUNTRY] || COUNTRY : 'id';

// 默认 bahasa
export const fetchPageJson = async (page: string, lang: string = defaultL) => {
  try {
    return await import(`@docs/pages/${lang}/${page}.json`);
  } catch {
    return await import(`../../defaults/${page}.json`);
  }
};

export const fetchFaqDetail = async (id: string, lang: string = defaultL) => {
  try {
    return await import(`@docs/faq/${lang}/${id}.json`);
  } catch {
    return {};
  }
};

export const fetchPageMarkdownJson = async (
  fileName: string,
  lang: string = defaultL,
) => {
  try {
    return await import(`@docs/pages/md/${lang}/${fileName}.json`);
  } catch {
    return {};
  }
};

export const fetchDownloadUrl = async (lang: string = defaultL) => {
  try {
    return await import(`@docs/download_url/${lang}.json`);
  } catch {
    return {};
  }
};

export const normalizeCustomFAQProps = (data: CustomFAQTmpl) => {
  return {
    title: data.title,
    questions: data.limit ? data.content.slice(0, data.limit) : data.content,
    linkText: data.linkText || '',
    placeholder: data.placeholder || '',
  };
};

export const normalizeFAQProps = (data: FAQTmpl) => {
  return {
    ...data,
    title: data.title,
    notMatchTips: data.notMatchTips || null,
    noResultTips: data.noResultTips || null,
    resultTips: data.resultTips || null,
    list: data.content.list,
    questions: data.limit
      ? data.content.hot.slice(0, data.limit)
      : data.content.hot,
    linkText: data.linkText || '',
    placeholder: data.placeholder || '',
  };
};

export const getAboutContent = (data: AboutTmpl) => {
  const { content: contentList, key } = data;
  let content = '';
  if (contentList.length > 0) {
    contentList.forEach((item) => {
      if (item.fileName === key) {
        content = item.content;
      }
    });
  }
  return content;
};

type GetPageImageType = (
  imageUrl: string,
  parentFolder?: string,
) => ImageDataType;

// 图片规则： [alt]{{image:xxx.png,250,250}}，三个参数分别是：alt, name，width，height
// 返回由 src, alt, width, height 组成的对象
export const getPageImages: GetPageImageType = (
  imgUrl: string,
  parentFolder: string = 'pages',
) => {
  // warn unexisted image, incase someone type in a wrong image name
  const requireImage = (fileName: string) =>
    require(`../../public/assets/images/${
      parentFolder ? `${parentFolder}/` : ''
    }${fileName}`).default;

  try {
    const imageReg = /(\[(.*)\])?{{image:(.*)}}/g;
    const result = imageReg.exec(imgUrl?.toString() || '');

    // try to get structure data from param first
    if (result) {
      const alt = result[2];
      const [imagePath, width, height] = (result[3] || '').split(',');

      return {
        src: requireImage(imagePath),
        alt,
        width,
        height,
      };
    } else {
      // for legacy usage
      return {
        src: requireImage(imgUrl),
      };
    }
  } catch {
    return { src: '' };
  }
};

const getIDMSiteHost = () => {
  if (ENV.includes('uat')) return `https://www-${ENV}.uat.seabank.co.id`;

  if (ENV.includes('sit'))
    return `https://www-${ENV}.test-stable.seabank.co.id`;

  if (ENV.includes('dev')) return `https://www-${ENV}.test.seabank.co.id`;

  if (ENV.includes('staging')) return `https://www.${ENV}.seabank.co.id`;

  return `https://www.seabank.co.id`;
};
const getPHMSiteHost = () => {
  if (ENV.includes('uat')) return `https://www-${ENV}.uat.seabank.ph`;

  if (ENV.includes('sit')) return `https://www-${ENV}.test-stable.seabank.ph`;

  if (ENV.includes('dev')) return `https://www-${ENV}.test.seabank.ph`;

  if (ENV.includes('staging')) return `https://www.${ENV}.seabank.ph`;

  return `https://www.seabank.ph`;
};
const getSGMSiteHost = () => {
  if (ENV.includes('uat')) return `https://www-${ENV}.uat.maribank.sg`;

  if (ENV.includes('sit')) return `https://www-${ENV}.test-stable.maribank.sg`;

  if (ENV.includes('dev')) return `https://www-${ENV}.test.maribank.sg`;

  if (ENV.includes('staging')) return `https://www.${ENV}.maribank.sg`;

  return `https://www.maribank.sg`;
};

export const getMSiteHost = ({
  id: getIDMSiteHost,
  ph: getPHMSiteHost,
  sg: getSGMSiteHost,
} as Record<string, typeof getPHMSiteHost>)[COUNTRY];

export const getPdf = (fileName: string) => {
  try {
    return `/assets/pdf/pages/${fileName}`;
  } catch {
    return '';
  }
};

export const generateQRCode = (
  url: string,
  options?: {
    margin?: number;
    width?: number;
    scale?: number;
  },
) => {
  return toDataURL(url, {
    margin: options?.margin,
    scale: options?.scale,
    width: options?.width,
  });
};
